



































import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import AddUnitDialog from './AddUnitDialog.vue';

export default Vue.extend({
  name: 'AddUnitDialogAction',

  components: {
    AddUnitDialog,
  },

  props: {
    isIcon: {
      default: true,
      type: Boolean,
    },
    thing: {
      type: Thing,
      required: true,
    },
  },

  computed: {
    hasMaxInstalledUnits(): boolean {
      const MAX_INSTALLED_UNITS = this.thing.isNetworkedThingsSupported
        ? 24
        : 8;
      return this.thing.numberOfInstalledUnits >= MAX_INSTALLED_UNITS;
    },
  },
});






















import Vue from 'vue';
import { Thing } from '../../models/Thing';

export default Vue.extend({
  name: 'SiteSignalStrength',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },

  computed: {
    isNotNA(): boolean {
      return this.thing.networkType !== 'N/A';
    },
    iconColor(): string {
      if (this.thing.signalStrengthInPercentage <= 25) {
        return 'error';
      }

      if (this.thing.signalStrengthInPercentage <= 50) {
        return 'orange';
      }

      if (this.thing.signalStrengthInPercentage <= 75) {
        return 'warning';
      }

      if (this.thing.signalStrengthInPercentage > 75) {
        return 'success';
      }

      return '';
    },
    iconType(): string {
      if (this.thing.signalStrengthInPercentage <= 25) {
        return 'mdi-network-strength-1';
      }

      if (this.thing.signalStrengthInPercentage <= 50) {
        return 'mdi-network-strength-2';
      }

      if (this.thing.signalStrengthInPercentage <= 75) {
        return 'mdi-network-strength-3';
      }

      if (this.thing.signalStrengthInPercentage > 75) {
        return 'mdi-network-strength-4';
      }

      return '';
    },
  },
});

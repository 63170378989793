











































































































import { cloneDeep, isEqual } from 'lodash-es';
import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import { VForm } from '../../../plugins/vuetifyTypes';
import { SnackbarType } from '../../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../../services/snackbar/SnackbarService';
import { ThingsService } from '../../../services/things/ThingsService';

export default Vue.extend({
  name: 'EditSiteDialog',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },

  watch: {
    isDialogOpen(): void {
      // ? Use timeout to avoid the user seeing the "loading" of values.
      setTimeout(() => this.loadThing(), 100);
    },
  },

  data() {
    return {
      newThing: Thing.empty(),

      isDialogOpen: false,
      isLoading: false,
    };
  },

  created(): void {
    this.loadThing();
  },

  computed: {
    isNewThingChanged(): boolean {
      return !isEqual(this.thing, this.newThing);
    },
  },

  methods: {
    loadThing(): void {
      const thingClone = cloneDeep(this.thing);
      this.newThing = new Thing(thingClone);
    },
    onClickOpen(): void {
      this.isDialogOpen = true;
    },
    onClickClose(): void {
      this.isDialogOpen = false;
    },
    async onSubmit(): Promise<void> {
      this.isLoading = true;
      try {
        const validation = (this.$refs.form as VForm).validate();

        if (validation) {
          await ThingsService.updateThing({
            newThing: this.newThing,
            oldThing: this.thing,
          });
          SnackbarService.open(
            this.$t('snackbar.updated', { name: this.thing.name }),
            SnackbarType.Success
          );
          this.isDialogOpen = false;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
      }
    },
  },
});





















































import Vue from 'vue';
import { Thing } from '../../models/Thing';
import { Unit } from '../../models/Unit';
import ConnectUnitDialogAction from './dialogs/ConnectUnitDialogAction.vue';
import UnitAlerts from './UnitAlerts.vue';

const DEFAULT_UNIT_IMAGE_HEIGHT = 100;

export default Vue.extend({
  name: 'UnitsListItem',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
  },

  watch: {
    unit: {
      deep: true,
      handler() {
        this.setUnitImageHeight();
      },
    },
  },

  components: {
    UnitAlerts,
    ConnectUnitDialogAction,
  },

  data() {
    return {
      unitImageHeight: DEFAULT_UNIT_IMAGE_HEIGHT,
    };
  },

  async mounted(): Promise<void> {
    await this.$nextTick();
    this.setUnitImageHeight();
  },

  methods: {
    setUnitImageHeight(): void {
      this.unitImageHeight = DEFAULT_UNIT_IMAGE_HEIGHT;
      if (this.$refs.unitImage && '$el' in this.$refs.unitImage) {
        this.unitImageHeight -= this.$refs.unitImage.$el.clientHeight;
      }
    },
  },
});





















































































































import Vue from 'vue';
import { Thing } from '../../models/Thing';

export default Vue.extend({
  name: 'SiteAlerts',

  props: {
    thing: {
      required: true,
      type: Thing,
    },
  },

  computed: {
    subscriptionPausedMailTo(): string {
      const subject = 'Activate paused subscription';
      const body = `${this.thing.name}\nGateway ID: ${this.thing.gatewayId}\nICCID: ${this.thing.iccid}`;

      const mailTo = `mailto:${this.thing.subscriptionEmail}?subject=${subject}&body=${body}`;
      return encodeURI(mailTo);
    },
  },
});






















import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import ConnectSiteDialog from './ConnectSiteDialog.vue';

export default Vue.extend({
  name: 'ConnectSiteDialogAction',

  components: { ConnectSiteDialog },

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },
});












import { isNumber } from 'lodash-es';
import Vue from 'vue';

export default Vue.extend({
  name: 'PageBreadcrumbs',

  data() {
    return {
      disabledEndpointEndings: ['unit'],
    };
  },

  computed: {
    breadcrumbs(): unknown[] {
      const paths = this.$route.path?.split('/').splice(1);

      let href = '';
      return paths.map(path => {
        href = [href, path].join('/');

        let text = path;
        if (!isNumber(path)) {
          text = text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
        }
        text = text.replace(/-/g, ' ');

        return {
          text,
          exact: true,
          disabled: this.disabledEndpointEndings.some(endpoint =>
            href.endsWith(endpoint)
          ),
          to: {
            path: href,
            query: this.$route.query,
          },
        };
      });
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"max-height":"400","outlined":"","tile":"","to":{
    name: 'Unit',
    params: {
      thingId: _vm.thing.thingName,
      unitId: _vm.unit.id,
    },
    query: _vm.$route.query,
  }}},[_c('unit-alerts',{ref:"unitImage",attrs:{"thing":_vm.thing,"unit":_vm.unit}}),_c('v-card-text',[_c('v-img',{attrs:{"height":_vm.unitImageHeight,"alt":_vm.unit.productType,"src":_vm.unit.productImageURL,"contain":""}})],1),_c('v-card-title',{staticClass:"text-truncate d-block"},[_vm._v(_vm._s(_vm.unit.name || _vm.unit.ipAddress))]),_c('v-card-subtitle',{staticClass:"text-truncate"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.unit.productType),expression:"unit.productType"}],staticClass:"text-caption"},[_vm._v(_vm._s(_vm.unit.productType))]),_c('br'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.unit.ipAddress),expression:"unit.ipAddress"}],staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.unit.ipAddress))])]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('connect-unit-dialog-action',{attrs:{"unit":_vm.unit,"thing":_vm.thing}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
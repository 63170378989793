
































































































































import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import { Unit } from '../../../models/Unit';
import { SnackbarType } from '../../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../../services/snackbar/SnackbarService';
import { ThingsMQTTService } from '../../../services/things/ThingsMQTTService';
import { UnitsService } from '../../../services/units/UnitsService';

const TEN_SECONDS_IN_MS = 1000 * 10;

export default Vue.extend({
  name: 'ImportSiteConfigDialog',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },

  watch: {
    isDialogOpen() {
      if (this.isDialogOpen) {
        window.addEventListener('beforeunload', this.beforeUnloadEvent);

        this.setupListeners();
      } else {
        window.removeEventListener('beforeunload', this.beforeUnloadEvent);
        this.clearData();
      }
    },
  },

  data() {
    return {
      hasStartedImport: false,
      isLoading: false,
      isDialogOpen: false,

      fileName: '',
      file: null,
      importedThing: Thing.empty(),
      importedUnits: new Array<Unit>(),
    };
  },

  computed: {
    hasImportedUnits(): boolean {
      return this.importedUnits.length > 0;
    },
    importProgress(): number {
      const numberOfUnitsToImportLeft = this.importedUnits.length;
      const numberOfUnitsToImport = this.importedThing.installedUnits.length;
      const decimalProgress =
        numberOfUnitsToImportLeft / numberOfUnitsToImport - 1;
      return Math.ceil(decimalProgress * -100);
    },
  },

  methods: {
    clearData(): void {
      this.file = null;
      this.hasStartedImport = false;
      this.isLoading = false;
      this.fileName = '';
      this.importedThing = Thing.empty();
      this.importedUnits = [];
    },
    beforeUnloadEvent(event: Event): void {
      event.preventDefault();
      event.returnValue = true;
    },
    onClickOpen(): void {
      this.isDialogOpen = true;
    },
    onClickClose(): void {
      this.isDialogOpen = false;
      this.hasStartedImport = false;
    },
    async onChangeImportConfig(file: File): Promise<void> {
      if (file) {
        const textDecoder = new TextDecoder();
        this.fileName = file?.name ?? '';
        const fileBuffer = await file?.arrayBuffer();
        const fileText = textDecoder.decode(fileBuffer);
        const fileJson = JSON.parse(fileText);

        this.importedThing = new Thing(fileJson);
        this.importedUnits = Array.from(this.importedThing.installedUnits);
      } else {
        this.clearData();
      }
    },
    onClearImportConfig(): void {
      this.clearData();
    },
    getNextUnit(): Unit | undefined {
      const unit = this.importedUnits.shift();
      return unit;
    },
    async setupListeners(): Promise<void> {
      await ThingsMQTTService.subscribeToUpdate(
        this.thing.domain.topic,
        this.thing.thingName
      );
      ThingsMQTTService.onMessage(async (topic, _, json) => {
        if (!this.hasStartedImport) return;

        const desiredState = json?.state?.desired;
        if (desiredState?.unit) {
          // ? There's still state, we need to wait.
          this.isLoading = true;
        } else {
          // ? There's no state continue to next unit.
          const unit = this.getNextUnit();
          await this.addUnitOrFinishImport(unit);
        }
      });
    },
    destroyListeners(): void {
      ThingsMQTTService.disconnect();
    },
    async addUnitOrFinishImport(unit?: Unit): Promise<void> {
      if (unit && unit.name && unit.productType && unit.ipAddress) {
        await UnitsService.addUnit({
          thing: this.thing,
          name: unit.name,
          ipAddress: unit.ipAddress,
          productType: unit.productType,
        });
        SnackbarService.open(
          this.$t('component.importSiteConfigDialog.importingUnit', {
            name: unit.name ?? unit.ipAddress ?? '-',
          }),
          SnackbarType.Normal
        );
      } else {
        SnackbarService.open(
          this.$t('component.importSiteConfigDialog.configImported', {
            name: this.thing.name ?? '-',
          }),
          SnackbarType.Success
        );
        this.isLoading = false;
        this.isDialogOpen = false;

        // ? Wait a bit before destroying listeners and clearing the data.
        setTimeout(() => {
          this.destroyListeners();
          this.clearData();
        }, TEN_SECONDS_IN_MS);
      }
    },
    async onSubmit(): Promise<void> {
      this.isLoading = true;
      try {
        this.importedUnits = Array.from(this.importedThing.installedUnits);

        const unit = this.getNextUnit();
        await this.addUnitOrFinishImport(unit);
        this.hasStartedImport = true;
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
      }
    },
  },
});













































import { debounce } from 'lodash';
import Vue from 'vue';
import { Thing } from '../../models/Thing';
import { ThingsMQTTService } from '../../services/things/ThingsMQTTService';
import UnitsAlarmsListItem from './UnitsAlarmsListItem.vue';

const TEN_MINUTES_IN_MS = 1000 * 60 * 10;

export default Vue.extend({
  name: 'UnitsAlarmsList',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },

  components: {
    UnitsAlarmsListItem,
  },

  computed: {
    debouncedForceThingUpdate(): () => void {
      return debounce(this.forceThingUpdate, TEN_MINUTES_IN_MS, {
        leading: true,
      });
    },
  },

  methods: {
    forceThingUpdate(): void {
      ThingsMQTTService.forceThingUpdate(
        this.thing.domain.topic,
        this.thing.thingName
      );
    },
    onClickListItem() {
      this.debouncedForceThingUpdate();
    },
  },
});

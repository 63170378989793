


















































































































import { AuthService } from '@/services/auth/AuthService';
import Vue from 'vue';
import { Thing } from '../../models/Thing';
import AddUnitDialogAction from '../units/dialogs/AddUnitDialogAction.vue';
import ConnectSiteDialogAction from './dialogs/ConnectSiteDialogAction.vue';
import EditSiteDialogAction from './dialogs/EditSiteDialogAction.vue';
import EditSiteLocationDialogAction from './dialogs/EditSiteLocationDialogAction.vue';
import EditSiteNetworkDialogAction from './dialogs/EditSiteNetworkDialogAction.vue';
import ImportSiteConfigDialogAction from './dialogs/ImportSiteConfigDialogAction.vue';
import UpdateSiteFirmwareDialogAction from './dialogs/UpdateSiteFirmwareDialogAction.vue';
import SiteAlerts from './SiteAlerts.vue';
import SiteParameters from './SiteParameters.vue';
import SiteSignalStrength from './SiteSignalStrength.vue';

export default Vue.extend({
  name: 'SiteInfo',

  components: {
    AddUnitDialogAction,
    ConnectSiteDialogAction,
    EditSiteDialogAction,
    EditSiteLocationDialogAction,
    EditSiteNetworkDialogAction,
    SiteAlerts,
    SiteParameters,
    SiteSignalStrength,
    UpdateSiteFirmwareDialogAction,
    ImportSiteConfigDialogAction,
  },

  data() {
    return {
      isSiteParametersOpen: false,
    };
  },

  props: {
    thing: {
      required: true,
      type: Thing,
    },
  },

  computed: {
    siteParametersIcon(): string {
      return this.isSiteParametersOpen ? 'mdi-chevron-up' : 'mdi-chevron-down';
    },
    isConnectSiteVisible(): boolean {
      return AuthService.isRootUser() || AuthService.isOSSUser();
    },
  },

  methods: {
    onClickToggleIsSiteParametersOpen(): void {
      this.isSiteParametersOpen = !this.isSiteParametersOpen;
    },
  },
});
















import { AuthService } from '@/services/auth/AuthService';
import Vue from 'vue';
import { Thing } from '../../models/Thing';

type ListItem = {
  label: unknown;
  value: () => unknown;
  visible: () => boolean;
};

export default Vue.extend({
  name: 'SiteParameters',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },

  computed: {
    visibleItems(): ListItem[] {
      return this.items.filter(item => item.visible());
    },
    items(): ListItem[] {
      return [
        {
          label: this.$t('component.siteParameters.name'),
          value: () => this.thing.name,
          visible: () => true,
        },
        {
          label: this.$t('component.siteParameters.description'),
          value: () => this.thing.description,
          visible: () => true,
        },
        {
          label: this.$t('component.siteParameters.domain'),
          value: () => this.thing.domainPath,
          visible: () => true,
        },
        {
          label: this.$t('component.siteParameters.id'),
          value: () => this.thing.thingName,
          visible: () => true,
        },
        {
          label: this.$t('component.siteParameters.created'),
          value: () => this.$dateTime(this.thing?.createdAt),
          visible: () => true,
        },
        {
          label: this.$t('component.siteParameters.createdBy'),
          value: () => this.thing.createdBy,
          visible: () => AuthService.isRootUser() || AuthService.isOSSUser(),
        },
        {
          label: this.$t('component.siteParameters.firmwareVersion'),
          value: () => this.thing.firmwareVersion,
          visible: () => true,
        },
        {
          label: this.$t('component.siteParameters.gatewayId'),
          value: () => this.thing.gatewayId,
          visible: () => true,
        },
        {
          label: this.$t('component.siteParameters.iccid'),
          value: () => this.thing.iccid,
          visible: () => true,
        },
        {
          label: this.$t('component.siteParameters.ip'),
          value: () => this.thing.ipAddress,
          visible: () => true,
        },
        {
          label: this.$t('component.siteParameters.lanIp'),
          value: () => this.thing.lanIpAddress,
          visible: () => true,
        },
        {
          label: this.$t('component.siteParameters.signalStrength'),
          value: () => this.thing.signalStrength,
          visible: () => true,
        },
      ];
    },
  },
});

































































































































import { AuthService } from '../../../services/auth/AuthService';
import { FirmwareService } from '../../../services/firmware/FirmwareService';
import { FirmwareVersion } from '../../../services/firmware/models/FirmwareVersion';
import { Thing } from '../../../models/Thing';
import { ThingsMQTTService } from '../../../services/things/ThingsMQTTService';
import { ThingStateFull } from '../../../services/things/models/ThingStateFull';
import { VForm } from '../../../plugins/vuetifyTypes';
import semver from 'semver';
import Vue from 'vue';
import { SnackbarType } from '../../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../../services/snackbar/SnackbarService';
import { DateTime } from 'luxon';

export default Vue.extend({
  name: 'UpdateSiteFirmwareDialog',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },

  watch: {
    isDialogOpen(): void {
      // ? Use timeout to avoid the user seeing the "loading" of values.
      setTimeout(() => {
        this.hasDownloadedConfig = false;
      }, 100);
    },
  },

  computed: {
    isUpdateButtonDisabled(): boolean {
      if (this.isUpgradingToV4 && this.hasThingTooOldFirmwareVersion) {
        return this.hasDownloadedConfig === false;
      }
      return this.hasThingLatestFirmwareVersion;
    },
    isUpgradingToV4(): boolean {
      const firmwareVersion = this.latestFirmwareVersion?.versionNumber;
      if (firmwareVersion) {
        return semver.gte(firmwareVersion, '4.0.0');
      }
      return false;
    },
    hasThingLatestFirmwareVersion(): boolean {
      if (
        this.thing.firmwareVersion &&
        this.latestFirmwareVersion.versionNumber
      ) {
        return semver.gte(
          this.thing.firmwareVersion,
          this.latestFirmwareVersion.versionNumber
        );
      }
      return true;
    },
    hasThingTooOldFirmwareVersion(): boolean {
      const firmwareVersion = this.thing.firmwareVersion;

      if (firmwareVersion) {
        return semver.lt(firmwareVersion, '3.0.4');
      }
      return false;
    },
    showDownloadConfigInformation(): boolean {
      return (
        !this.hasThingLatestFirmwareVersion &&
        this.isUpgradingToV4 &&
        !this.hasThingTooOldFirmwareVersion
      );
    },
  },

  data() {
    return {
      isLoading: false,
      isDialogOpen: false,
      hasDownloadedConfig: false,
      latestFirmwareVersion: {} as FirmwareVersion,
    };
  },

  async created(): Promise<void> {
    this.isLoading = true;

    await this.loadLatestFirmwareVersion();

    this.isLoading = false;
  },

  methods: {
    async loadLatestFirmwareVersion(): Promise<void> {
      const firmwareVersion = await FirmwareService.getLatestFirmwareVersion();
      if (firmwareVersion) {
        this.latestFirmwareVersion = { ...firmwareVersion };
      }
    },
    onClickDownloadConfiguration(): void {
      const json = JSON.stringify(this.thing, null, 2);
      const dataURL = `data:text/json;charset=utf-8,${encodeURI(json)}`;

      const thingName = (this.thing.name ?? 'Site').replace(/ /g, '-');
      const today = DateTime.now().toLocaleString();
      const fileName = `${this.thing.thingName}-${thingName}-${today}.json`;

      const anchor = document.createElement('a');
      anchor.setAttribute('href', dataURL);
      anchor.setAttribute('download', fileName);
      anchor.setAttribute('style', 'display: none;');

      document.body.appendChild(anchor);
      anchor.click();
      this.hasDownloadedConfig = true;
      document.body.removeChild(anchor);
    },
    onClickOpen(): void {
      this.isDialogOpen = true;
    },
    onClickClose(): void {
      this.isDialogOpen = false;
    },
    createState(): ThingStateFull {
      const state: ThingStateFull = {};

      if (
        this.latestFirmwareVersion?.versionNumber !== this.thing.firmwareVersion
      ) {
        const { phone } = AuthService.getCurrentUser();
        // eslint-disable-next-line @typescript-eslint/camelcase
        state.fw_version_update = {
          version: this.latestFirmwareVersion.versionNumber,
          fileName: this.latestFirmwareVersion.fileName,
          md5: this.latestFirmwareVersion.md5,
          phoneNumber: phone,
          supportPhoneNumber: this.latestFirmwareVersion.phoneNumber,
          routerIp: this.thing.lanIpAddress,
        };
      }

      return state;
    },
    onSubmit(): void {
      this.isLoading = true;
      try {
        const validation = (this.$refs.form as VForm).validate();
        const state = this.createState();

        if (validation && state !== {}) {
          ThingsMQTTService.publishUpdate({
            domainPath: this.thing.domain.topic,
            thingId: this.thing.thingName,
            state,
          });
          SnackbarService.open(
            this.$t('snackbar.updated', { name: this.thing.name }),
            SnackbarType.Success
          );
          this.isDialogOpen = false;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
      }
    },
  },
});






































import Vue from 'vue';
import { Thing } from '../../models/Thing';
import { Unit } from '../../models/Unit';
import { ThingUnitAlarm } from '../../services/things/models/ThingUnitAlarm';
import AlarmsListItem from './AlarmsListItem.vue';

export default Vue.extend({
  name: 'AlarmsList',

  components: {
    AlarmsListItem,
  },

  props: {
    thing: {
      type: Thing,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
  },

  computed: {
    alarms(): ThingUnitAlarm[] {
      return this.unit.sortedAlarms;
    },
  },
});

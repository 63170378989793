



































































































































































import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import { Unit } from '../../../models/Unit';
import { VForm } from '../../../plugins/vuetifyTypes';
import { SnackbarType } from '../../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../../services/snackbar/SnackbarService';
import { ThingStateFull } from '../../../services/things/models/ThingStateFull';
import { ThingsMQTTService } from '../../../services/things/ThingsMQTTService';
import { UnitsService } from '../../../services/units/UnitsService';
import { formValidator } from '../../../utils/FormValidator';
import { isDefined } from '../../../utils/Utils';

const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

export default Vue.extend({
  name: 'AddUnitDialog',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },

  watch: {
    isDialogOpen(): void {
      if (!this.isDialogOpen) {
        (this.$refs.form as VForm).reset();
      }
    },
  },

  data() {
    return {
      formValidator,
      formData: {
        name: '',
        productType: '',
        ipAddress: '',
        username: '',
        password: '',
        confirmPassword: '',
      },
      isDialogOpen: false,
      isLoading: false,
      isLoadingTimeout: -1,
    };
  },

  computed: {
    ipAddresses(): string[] {
      const thingUnits = this.thing.units ?? [];
      const units = thingUnits.map(unit => unit.ipAddress).filter(isDefined);

      return units;
    },
    isUsernamePasswordSupported(): boolean {
      return new Unit({
        ...Unit.empty(),
        productType: this.formData.productType,
      }).isUsernamePasswordSupported;
    },
    atUnitCapacity(): boolean {
      const units = (this.thing.units ?? []).filter(unit => unit.installed);
      if (!this.thing.nineToTwentyFour) {
        return units.length >= 8;
      } else {
        return units.length >= 24;
      }
    },
    nineToTwentyFourMailTo(): string {
      const subject = 'Activate 9 - 24';
      const body = `${this.thing.name}\nGateway ID: ${this.thing.gatewayId}\nICCID: ${this.thing.iccid}`;
      const mailTo = `mailto:${this.thing.subscriptionEmail}?subject=${subject}&body=${body}`;
      return encodeURI(mailTo);
    },
  },

  methods: {
    onClickOpen(): void {
      this.isDialogOpen = true;
    },
    onClickClose(): void {
      this.isDialogOpen = false;
    },
    async onSubmit(): Promise<void> {
      this.isLoading = true;

      (this.$refs['myComboBox'] as HTMLElement)?.blur();

      this.$nextTick(async () => {
        try {
          const validation = (this.$refs.form as VForm).validate();

          if (validation) {
            await ThingsMQTTService.subscribeToUpdate(
              this.thing.domain.topic,
              this.thing.thingName
            );
            ThingsMQTTService.onMessage(async (topic, _, json) => {
              if (!this.isDialogOpen) return;

              const reportedState = json?.state?.reported as ThingStateFull;
              const reportedThing = Thing.fromThingState(
                this.thing,
                reportedState
              );
              const hasAddedUnit = reportedThing.units?.find(
                unit =>
                  unit.name === this.formData.name &&
                  unit.productType === this.formData.productType &&
                  unit.ipAddress === this.formData.ipAddress
              );
              if (hasAddedUnit) {
                const unit = reportedThing.installedUnits.pop();
                if (unit) {
                  SnackbarService.open(
                    this.$t('snackbar.added', { name: this.formData.name }),
                    SnackbarType.Success
                  );
                  this.isDialogOpen = false;
                  this.isLoading = false;

                  clearTimeout(this.isLoadingTimeout);
                }
              }
            });

            await UnitsService.addUnit({
              thing: this.thing,
              name: this.formData.name,
              productType: this.formData.productType,
              ipAddress: this.formData.ipAddress,
              username: this.formData.username,
              password: this.formData.password,
            });

            // ? If the isLoading for some reason isn't toggled back to false use a timeout to ensure it is set to false.
            this.isLoadingTimeout = window.setTimeout(() => {
              this.isLoading = false;
              clearTimeout(this.isLoadingTimeout);
            }, TWO_MINUTES_IN_MS);
          } else {
            this.isLoading = false;
          }
        } catch (error) {
          SnackbarService.open(
            this.$t('snackbar.somethingWentWrong'),
            SnackbarType.Error
          );
          this.isLoading = false;
        }
      });
    },
  },
});









































































































import { isDefined } from '@/utils/Utils';
import { cloneDeep, isEqual } from 'lodash-es';
import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import { VForm } from '../../../plugins/vuetifyTypes';
import { SnackbarType } from '../../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../../services/snackbar/SnackbarService';
import { ThingsService } from '../../../services/things/ThingsService';
import { getDefaultCoordinates } from '../../../utils/GoogleMapsUtils';

const defaultZoom = 16;

const AutocompleteService = new google.maps.places.AutocompleteService();

export default Vue.extend({
  name: 'EditSiteLocationDialog',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },

  watch: {
    async isDialogOpen(): Promise<void> {
      // ? Use timeout to avoid the user seeing the "loading" of values.
      setTimeout(() => {
        this.loadThing();
        this.autocompleteSearchTerm = {} as google.maps.places.AutocompletePrediction;
      }, 100);

      if (this.isDialogOpen) {
        await this.setupMap();
        this.drawThingMarker();
      }
    },
  },

  data() {
    return {
      map: undefined as google.maps.Map | undefined,
      marker: undefined as google.maps.Marker | undefined,
      autocompleteSearch: undefined as google.maps.places.SearchBox | undefined,
      autocompleteSearchTerm: {} as google.maps.places.AutocompletePrediction,
      autocompleteSearchItems: new Array<
        google.maps.places.QueryAutocompletePrediction
      >(), // eslint-disable-line prettier/prettier

      newThing: Thing.empty(),

      isDialogOpen: false,
      isLoading: false,
    };
  },

  async mounted(): Promise<void> {
    this.loadThing();
    await this.setupMap();
    this.drawThingMarker();
  },

  computed: {
    isNewThingChanged(): boolean {
      return !isEqual(this.thing, this.newThing);
    },
    hasManualLocation(): boolean {
      return isDefined(this.thing.latitudeLongitude);
    },
  },

  methods: {
    loadThing(): void {
      const thingClone = cloneDeep(this.thing);
      this.newThing = new Thing(thingClone);
    },
    async setupMap(): Promise<void> {
      await this.$nextTick();
      const mapElement = this.$refs.map;

      if (mapElement instanceof HTMLElement) {
        const coordinates = await getDefaultCoordinates();
        this.map = new google.maps.Map(mapElement, {
          center: { lat: coordinates.latitude, lng: coordinates.longitude },
          zoom: defaultZoom,
        });

        this.map.addListener(
          'click',
          ({ latLng }: google.maps.MapMouseEvent) => {
            const latitude = latLng?.lat();
            const longitude = latLng?.lng();

            if (latitude && longitude) {
              this.updateThingLocation(latitude, longitude);
            }
          }
        );
      }
    },
    drawThingMarker(): void {
      if (this.newThing.coordinates) {
        this.marker?.setMap(null);

        const position = {
          lat: this.newThing.coordinates.latitude,
          lng: this.newThing.coordinates.longitude,
        };

        this.marker = new google.maps.Marker({
          position,
          title: this.newThing.thingName,
          icon: {
            url: '/img/maps/map-marker-grey.png',
            scaledSize: new google.maps.Size(48, 48),
          },
          map: this.map,
        });
        this.map?.panTo(position);
      }
    },
    onInputSetPlace(value: google.maps.places.AutocompletePrediction): void {
      this.autocompleteSearchTerm = value;
      if (this.map && this.autocompleteSearchTerm.place_id) {
        const placesService = new google.maps.places.PlacesService(this.map);
        placesService.getDetails(
          {
            placeId: this.autocompleteSearchTerm.place_id,
          },
          (result, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              const latitude = result?.geometry?.location?.lat();
              const longitude = result?.geometry?.location?.lng();

              if (latitude && longitude) {
                this.updateThingLocation(latitude, longitude);
              }
            }
          }
        );
      }
    },
    onSearchInputAutocomplete(value: string): void {
      if (value) {
        AutocompleteService.getQueryPredictions(
          { input: value },
          (predictions, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              this.autocompleteSearchItems = Array.from(predictions);
            }
          }
        );
      } else {
        this.autocompleteSearchItems = [];
      }
    },
    updateThingLocation(latitude: number, longitude: number): void {
      this.newThing.latitudeLongitude = [latitude, longitude].join(', ');
      this.drawThingMarker();
    },
    onClickOpen(): void {
      this.isDialogOpen = true;
    },
    onClickClose(): void {
      this.isDialogOpen = false;
    },
    async onSubmit(): Promise<void> {
      this.isLoading = true;
      try {
        const validation = (this.$refs.form as VForm).validate();

        if (validation) {
          await ThingsService.updateThing({
            newThing: this.newThing,
            oldThing: this.thing,
          });
          SnackbarService.open(
            this.$t('snackbar.updated', { name: this.thing.name }),
            SnackbarType.Success
          );
          this.isDialogOpen = false;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
      }
    },
    async onClickReset(): Promise<void> {
      this.isLoading = true;
      try {
        this.newThing.latitudeLongitude = null;
        await ThingsService.updateThing({
          newThing: this.newThing,
          oldThing: this.thing,
        });
        SnackbarService.open(
          this.$t('snackbar.updated', { name: this.thing.name }),
          SnackbarType.Success
        );
        this.isDialogOpen = false;
        this.isLoading = false;
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
      }
    },
  },
});


























































import Vue from 'vue';
import { Thing } from '../../models/Thing';
import { Unit } from '../../models/Unit';
import { isDefined } from '../../utils/Utils';

const DRAW_INTERVAL_IN_MS = 3000;

type ListItem = {
  label: unknown;
  value: () => unknown;
};

export default Vue.extend({
  name: 'UnitParameters',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
  },

  watch: {
    'unit.sfp.value'() {
      this.drawSFP();
    },
    'unit.hx.value'() {
      this.drawHX();
    },
  },

  data() {
    return {
      drawGaugesInterval: -1,
    };
  },

  methods: {
    drawSFP(): void {
      const data = google.visualization.arrayToDataTable([
        ['Label', 'Value'],
        [this.$t('unit.sfp'), this.unit.sfp?.value],
      ]);
      const options: google.visualization.GaugeChartOptions = {
        width: 150,
        height: 150,
        greenFrom: 0,
        greenTo: 2,
        yellowFrom: 2,
        yellowTo: 3,
        redFrom: 3,
        redTo: 4,
        majorTicks: ['0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4'],
        min: 0,
        max: 4,
      };
      const sfpGauge = this.$el?.querySelector('[data-id="sfpGauge"]');
      if (sfpGauge instanceof HTMLElement && isDefined(this.unit.sfp?.value)) {
        const chart = new google.visualization.Gauge(sfpGauge);

        chart.draw(data, options);
        this.$forceUpdate();
      }
    },
    drawHX(): void {
      const data = google.visualization.arrayToDataTable([
        ['Label', 'Value'],
        [this.$t('unit.hx'), this.unit.hx?.value],
      ]);
      const hxFormat = new google.visualization.NumberFormat({
        suffix: '%',
        fractionDigits: 0,
      });
      hxFormat.format(data, 1);
      const options: google.visualization.GaugeChartOptions = {
        width: 150,
        height: 150,
        greenFrom: 75,
        greenTo: 100,
        yellowFrom: 25,
        yellowTo: 75,
        redFrom: 0,
        redTo: 25,
        majorTicks: [
          '10',
          '20',
          '30',
          '40',
          '50',
          '60',
          '70',
          '80',
          '90',
          '100',
        ],
        min: 0,
        max: 100,
      };

      const hxGauge = this.$el?.querySelector('[data-id="hxGauge"]');
      if (hxGauge instanceof HTMLElement && isDefined(this.unit.hx?.value)) {
        const chart = new google.visualization.Gauge(hxGauge);

        chart.draw(data, options);
        this.$forceUpdate();
      }
    },
  },

  async mounted(): Promise<void> {
    await this.$nextTick();
    this.drawSFP();
    this.drawHX();

    this.drawGaugesInterval = window.setInterval(() => {
      this.drawSFP();
      this.drawHX();
    }, DRAW_INTERVAL_IN_MS);
  },

  beforeDestroy(): void {
    clearInterval(this.drawGaugesInterval);
  },

  computed: {
    hasSFP(): boolean {
      return isDefined(this.unit.sfp);
    },
    hasHX(): boolean {
      return isDefined(this.unit.hx);
    },
    items(): ListItem[] {
      const items = [
        {
          label: this.$t('unit.operationStatus'),
          value: () =>
            isDefined(this.unit.operationStatus)
              ? this.$t(`operationStatus._${this.unit.operationStatus}`)
              : undefined,
        },
        {
          label: this.$t('unit.iqLogicVersion'),
          value: () => this.unit?.version,
        },
        {
          label: this.$t('unit.supplyAir'),
          value: () => this.unit?.supplyAir?.toString(),
        },
        {
          label: this.$t('unit.extractAir'),
          value: () => this.unit?.extractAir?.toString(),
        },
        {
          label: this.$t('unit.supplyAirDuct'),
          value: () => this.unit?.supplyAirDuct?.toString(),
        },
        {
          label: this.$t('unit.extractAirDuct'),
          value: () => this.unit?.extractAirDuct?.toString(),
        },
        {
          label: this.$t('unit.supplyAirTemperature'),
          value: () => this.unit?.supplyAirTemperature?.toString(),
        },
        {
          label: this.$t('unit.extractAirTemperature'),
          value: () => this.unit?.extractAirTemperature?.toString(),
        },
        {
          label: this.$t('unit.outdoorAirTemperature'),
          value: () => this.unit?.outdoorAirTemperature?.toString(),
        },
        {
          label: this.$t('unit.heatPowerRecovery'),
          value: () => this.unit?.heatPowerRecovery?.toString(),
        },
        {
          label: this.$t('unit.coolPowerRecovery'),
          value: () => this.unit?.coolPowerRecovery?.toString(),
        },
        {
          label: this.$t('unit.ahuEnergyConsumption'),
          value: () => this.unit?.ahuEnergyConsumption?.toString(),
        },
        {
          label: this.$t('unit.heatCoolEnergyConsumption'),
          value: () => this.unit?.heatCoolEnergyConsumption?.toString(),
        },
      ];

      return items;
    },
    visibleItems(): ListItem[] {
      return this.items.filter(item => isDefined(item.value()));
    },
  },
});
































import Vue from 'vue';
import { Thing } from '../../models/Thing';
import { Unit } from '../../models/Unit';
import { AlarmsService } from '../../services/alarms/AlarmsService';
import AlarmsList from '../alarms/AlarmsList.vue';

export default Vue.extend({
  name: 'UnitsAlarmsListItem',

  components: {
    AlarmsList,
  },

  props: {
    thing: {
      type: Thing,
      required: true,
    },
    unit: {
      required: true,
      type: Unit,
    },
  },

  methods: {
    onClickConnect(): void {
      alert('Connect');
    },
  },

  computed: {
    alarmsSummary(): string {
      const alarms = this.unit.alarms ?? [];
      const numberOfAlarms = alarms.length;
      const alarm = AlarmsService.getHighestPrioAlarm(alarms);

      if (alarm) {
        const alarmText = this.$t(`alarm._${alarm.id}`);

        return this.$t('component.unitsAlarmsListItem.alarmSummary', {
          alarmId: alarm.id,
          alarmText,
          alarms: numberOfAlarms - 1,
        }) as string;
      }
      return '';
    },
  },
});

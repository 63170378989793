import semver from 'semver';
import { AxiosInstance } from '../../api/axios';
import { BACKEND_REST_API_BASE_URL } from '../../config';
import { AuthService } from '../auth/AuthService';
import { FirmwareServiceInterface } from './FirmwareServiceInterface';
import { FirmwareStage } from './models/FirmwareStage';
import { FirmwareVersion } from './models/FirmwareVersion';

class FirmwareServiceImplementation implements FirmwareServiceInterface {
  async getFirmwareVersions(): Promise<FirmwareVersion[]> {
    const ENDPOINT = '/firmware';

    const firmwareStage = AuthService.isRootUser()
      ? FirmwareStage.InternalBeta
      : FirmwareStage.Release;

    const headers = {
      authorization: AuthService.getToken(),
      'x-auth-token': AuthService.getToken(),
    };
    const { data = [] }: { data: FirmwareVersion[] } = await AxiosInstance.get(
      `${BACKEND_REST_API_BASE_URL}${ENDPOINT}/${firmwareStage}`,
      {
        baseURL: '',
        headers,
      }
    );

    const descendingFirmwareVersions = [...data]
      .sort((a, b) => semver.compare(a.versionNumber, b.versionNumber))
      .reverse();

    return descendingFirmwareVersions;
  }

  async getLatestFirmwareVersion(): Promise<FirmwareVersion | undefined> {
    const [latestFirmwareVersion] = await this.getFirmwareVersions();
    return latestFirmwareVersion;
  }
}

export const FirmwareService = new FirmwareServiceImplementation();






















import Vue from 'vue';
import { Thing } from '../../models/Thing';
import UnitsListItem from './UnitsListItem.vue';

export default Vue.extend({
  name: 'UnitsList',

  components: {
    UnitsListItem,
  },

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },
});

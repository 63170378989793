







































import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import EditSiteLocationDialog from './EditSiteLocationDialog.vue';

export default Vue.extend({
  name: 'EditSiteLocationDialogAction',

  props: {
    isIcon: {
      default: true,
      type: Boolean,
    },
    thing: {
      type: Thing,
      required: true,
    },
  },

  components: {
    EditSiteLocationDialog,
  },
});

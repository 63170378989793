








































































import { isDefined } from '@/utils/Utils';
import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import { Unit } from '../../../models/Unit';
import { ThingsMQTTService } from '../../../services/things/ThingsMQTTService';
import { getProxyTTLs } from '../../../utils/ProxyUtils';
import { ProxyService } from '@/services/proxy/ProxyService';

const defaultProxyTTL = getProxyTTLs()[0].proxyTTL;

export default Vue.extend({
  name: 'ConnectUnitDialog',

  props: {
    unit: {
      type: Unit,
      required: true,
    },
    thing: {
      type: Thing,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      isLoadingTimeout: -1,

      isDialogOpen: false,
      connectProxyTTL: defaultProxyTTL,
      proxyTTLs: getProxyTTLs(),
      proxyURL: undefined as string | undefined,
    };
  },

  beforeDestroy() {
    ThingsMQTTService.disconnect();
  },

  computed: {
    connectButtonText(): unknown {
      return isDefined(this.proxyURL)
        ? this.$t('component.connectUnitDialog.openConnection')
        : this.$t('component.connectUnitDialog.connectFor', {
            value: this.readableConnectProxyTTL,
          });
    },
    readableConnectProxyTTL(): string {
      const proxyTTL = this.proxyTTLs.find(
        item => item.proxyTTL === this.connectProxyTTL
      );

      return (proxyTTL?.text as string) ?? '';
    },
  },

  methods: {
    onClickOpen(): void {
      this.isDialogOpen = true;
    },
    onClickClose(): void {
      this.isDialogOpen = false;
      this.proxyURL = undefined;
    },
    async onSubmit(): Promise<void> {
      if (this.proxyURL) {
        window.open(this.proxyURL, '_blank');
        this.onClickClose();
      } else {
        this.isLoading = true;
        this.proxyURL = await ProxyService.createProxy(
          this.thing.ipAddress,
          this.unit.extPort,
          this.connectProxyTTL
        );
        this.isLoading = false;
        window.open(this.proxyURL, '_blank');
      }
    },
  },
});





































import { debounce } from 'lodash-es';
import Vue from 'vue';
import PageBreadcrumbs from '../components/app/PageBreadcrumbs.vue';
import SiteInfo from '../components/sites/SiteInfo.vue';
import SiteLastUpdate from '../components/sites/SiteLastUpdate.vue';
import UnitsAlarmsList from '../components/units/UnitsAlarmsList.vue';
import UnitsList from '../components/units/UnitsList.vue';
import UnitsParametersList from '../components/units/UnitsParametersList.vue';
import { Thing } from '../models/Thing';
import { Unit } from '../models/Unit';
import { LoadingService } from '../services/loading/LoadingService';
import { ThingStateFull } from '../services/things/models/ThingStateFull';
import { ThingsMQTTService } from '../services/things/ThingsMQTTService';
import { ThingsService } from '../services/things/ThingsService';
import { pickDefined } from '../utils/Utils';

const REFRESH_INTERVAL_IN_MS = 1000 * 10;

const WINDOW_UTILS_KEY = '__SitePage__';

export default Vue.extend({
  name: 'SitePage',

  props: {
    thingId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      refreshInterval: -1,
      hasSentClearState: false,
      thing: Thing.empty(),
      loadThingInterval: -1,
    };
  },

  components: {
    PageBreadcrumbs,
    SiteInfo,
    SiteLastUpdate,
    UnitsAlarmsList,
    UnitsList,
    UnitsParametersList,
  },

  async created(): Promise<void> {
    LoadingService.setIsLoading(true);

    await this.loadThing(true);
    this.setupListeners();

    LoadingService.setIsLoading(false);

    this.setupWindowUtils();
    this.refreshInterval = window.setInterval(() => {
      this.loadThing(true);
    }, REFRESH_INTERVAL_IN_MS);
  },

  beforeDestroy(): void {
    this.destroyListeners();
    this.destroyWindowUtils();
    window.clearInterval(this.refreshInterval);
  },

  computed: {
    debouncedLoadThing(): () => void {
      return debounce(() => this.loadThing(true), REFRESH_INTERVAL_IN_MS);
    },
  },

  methods: {
    async loadThing(forceRefresh = false): Promise<void> {
      const thing = await ThingsService.getThing(this.thingId, forceRefresh);
      this.thing = new Thing(thing);
    },
    setupListeners(): void {
      ThingsMQTTService.subscribeToUpdate(
        this.thing.domain.topic,
        this.thing.thingName,
        true
      );
      this.thing.units?.forEach(unit => {
        ThingsMQTTService.subscribeToNetworkedThingUpdate(
          this.thing.domain.topic,
          this.thing.thingName,
          unit.id
        );
      });
      ThingsMQTTService.onMessage(async (topic, _, json) => {
        const reportedState = json?.state?.reported;
        if (!reportedState) return;

        const units = this.thing.units ?? [];
        const isUnitTopic = units.some(unit => topic.includes(unit.id));
        const unitIndex = units.findIndex(unit => topic.includes(unit.id));
        const unit = units[unitIndex];
        if (isUnitTopic && unitIndex >= 0 && unit) {
          const updatedUnit = pickDefined(
            Unit.fromThingUnit(reportedState, unit.id)
          );

          const fullUnit = new Unit({ ...unit, ...updatedUnit });

          units?.splice(unitIndex, 1, fullUnit);
        } else {
          const updatedThing = Thing.fromThingState(
            this.thing,
            reportedState as ThingStateFull
          );
          this.handleFirmwareUpdate(updatedThing, this.thing);
          this.thing = new Thing({
            ...pickDefined(updatedThing),
            ...this.thing,
          });
        }

        this.debouncedLoadThing();
      });
    },
    async handleFirmwareUpdate(
      updatedThing: Thing,
      oldThing: Thing
    ): Promise<void> {
      if (
        !this.hasSentClearState &&
        updatedThing.satisfiesFirmwareVersion('=4.0.0') &&
        oldThing.satisfiesFirmwareVersion('^3.x')
      ) {
        await this.clearState();
        this.hasSentClearState = true;
      }
    },
    destroyListeners(): void {
      ThingsMQTTService.disconnect();
    },
    setupWindowUtils(): void {
      Object.assign(window, {
        [WINDOW_UTILS_KEY]: {
          utils: {
            clearState: this.clearState,
            updateCell: this.updateCell,
          },
        },
      });
    },
    destroyWindowUtils(): void {
      Object.assign(window, {
        [WINDOW_UTILS_KEY]: null,
      });
    },
    clearState(): void {
      const state: ThingStateFull = {};
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.clear_state = true;
      ThingsMQTTService.publishUpdate({
        domainPath: this.thing.domain.topic,
        thingId: this.thing.thingName,
        state,
      });
    },
    updateCell(): void {
      const state: ThingStateFull = {};
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.set_cell = true;
      ThingsMQTTService.publishUpdate({
        domainPath: this.thing.domain.topic,
        thingId: this.thing.thingName,
        state,
      });
    },
  },
});

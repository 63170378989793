








































import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import EditSiteNetworkDialog from './EditSiteNetworkDialog.vue';

export default Vue.extend({
  name: 'EditSiteNetworkDialogAction',

  components: {
    EditSiteNetworkDialog,
  },

  props: {
    isIcon: {
      default: true,
      type: Boolean,
    },
    thing: {
      type: Thing,
      required: true,
    },
  },
});

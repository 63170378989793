import { AxiosInstance } from '@/api/axios';
import { Optional } from '@/utils/Optional';
import { BACKEND_REST_API_BASE_URL } from '@/config';
import { AuthService } from '@/services/auth/AuthService';

class ProxyServiceImplementation implements ProxyServiceInterface {
  async createProxy(
    ipAddress: Optional<string>,
    port: Optional<number>,
    proxyTTL: Optional<number>
  ): Promise<string> {
    if (ipAddress == null || port == null || proxyTTL == null)
      return Promise.reject('Invalid input');
    const { data } = await AxiosInstance.get<string>(
      `${BACKEND_REST_API_BASE_URL}/proxy`,
      {
        headers: { authorization: AuthService.getToken() },
        params: { ipAddress, port, ttl: proxyTTL },
      }
    );
    console.log(data);
    return Promise.resolve(data);
  }
}

export const ProxyService = new ProxyServiceImplementation();










































import Vue from 'vue';
import UpdateSiteFirmwareDialog from './UpdateSiteFirmwareDialog.vue';
import { Thing } from '../../../models/Thing';

export default Vue.extend({
  name: 'UpdateSiteFirmwareDialogAction',

  components: {
    UpdateSiteFirmwareDialog,
  },

  props: {
    isIcon: {
      default: true,
      type: Boolean,
    },
    thing: {
      type: Thing,
      required: true,
    },
  },
});

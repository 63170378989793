




























import Vue from 'vue';
import { Thing } from '../../models/Thing';
import { Unit } from '../../models/Unit';
import { isDefined } from '../../utils/Utils';
import UnitParameters from './UnitParameters.vue';

export default Vue.extend({
  name: 'UnitsParametersListItem',

  props: {
    unit: {
      type: Unit,
      required: true,
    },
    thing: {
      type: Thing,
      required: true,
    },
  },

  computed: {
    operationStatus(): string {
      if (isDefined(this.unit.operationStatus)) {
        return this.$t(
          `operationStatus._${this.unit.operationStatus}`
        ) as string;
      }

      return '';
    },
  },

  components: {
    UnitParameters,
  },
});

















import Vue from 'vue';
import { Thing } from '../../models/Thing';
import { Unit } from '../../models/Unit';
export default Vue.extend({
  name: 'UnitAlerts',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
  },
});









import Vue from 'vue';
import { Thing } from '../../models/Thing';

export default Vue.extend({
  name: 'SiteLastUpdate',

  props: {
    thing: {
      type: Thing,
      required: true,
    },
  },
});





































import Vue from 'vue';
import { Thing } from '../../../models/Thing';
import EditSiteDialog from './EditSiteDialog.vue';

export default Vue.extend({
  name: 'EditSiteDialogAction',

  props: {
    isIcon: {
      default: true,
      type: Boolean,
    },
    thing: {
      type: Thing,
      required: true,
    },
  },

  components: {
    EditSiteDialog,
  },
});












































import Vue, { PropOptions } from 'vue';
import { ThingUnitAlarm } from '../../services/things/models/ThingUnitAlarm';
import { AlarmPrio } from '../../services/alarms/models/AlarmPrio';

export default Vue.extend({
  name: 'AlarmsListItem',

  props: {
    alarm: {
      type: Object,
      required: true,
    } as PropOptions<ThingUnitAlarm>,
  },

  data() {
    return {
      AlarmPrio,
    };
  },
});
